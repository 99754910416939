<template>
  <div
    v-if="!isDataReviewReady"
  >
    NO DATA AVAILABLE
  </div>
  <b-card v-else>
    <template #header>
      <div style="display: contents;">
        <h4>View Data for {{ barcodeData.duBarcode || barcodeData.stBarcode || barcodeData.saBarcode }}</h4>
        <b-dropdown
          id="dropdown-1"
          text="Select Data Type"
          variant="outline-primary"
          class="scrollable-dropdown"
          drop-left
        >
          <b-dropdown-item
            v-for="item in dropdownItems"
            :key="item.type"
            @click="reviewJson(item.type)"
          >
            {{ item.label }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>
    <!-- Comments Section -->
    <b-form-group v-if="currentStep !== 'View Data'">
      <b-form-textarea
        id="comments"
        v-model="comments"
        rows="3"
        placeholder="Enter your comments here..."
      />
    </b-form-group>

    <!-- Publish and Reject Buttons  -->
    <div
      v-if="currentStep !== 'View Data' "
      class="d-flex justify-content-between"
    >
      <div>
        <b-button
          class="mr-2"
          variant="primary"
          @click="changeDataStatus(currentStep === 'Final Review' ? 'Finalized' : 'Accepted')"
        >
          Accept
        </b-button>
        <b-button
          variant="danger"
          @click="changeDataStatus('Rejected')"
        >
          Reject
        </b-button>
      </div>
      <b-button
        v-if="currentStep === 'Final Review'"
        variant="success"
        :disabled="barcodeData.dataReview.status !== 'Finalized'"
        @click="publishData"
      >
        Publish
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { BButton, BFormGroup, BFormTextarea } from 'bootstrap-vue'
import { axiosReportsIns } from '@/libs/axios'

export default {
  components: {
    BButton,
    BFormGroup,
    BFormTextarea,
  },
  props: {
    currentStep: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      barcodeData: null,
      comments: '', // Initialize comments as an empty string
      dropdownItems: [
        { type: 'base-data', label: 'Meta Data' },
        { type: 'biological-age-score', label: 'Biological Age Score' },
        { type: 'comorbidities', label: 'Comorbidities' },
        { type: 'diet-chart', label: 'Diet Chart' },
        { type: 'mb-wellness-risk', label: 'MB Wellness Risk' },
        { type: 'mb-condition-risk', label: 'MB Condition Risk' },
        { type: 'mb-condition', label: 'MB Condition' },
        { type: 'mb-wellness', label: 'MB Wellness' },
        { type: 'host-rank', label: 'Host rank' },
        { type: 'host-genotype', label: 'Host genotype' },
        { type: 'inference', label: 'Inference' },
        { type: 'health-score', label: 'Health score' },
        { type: 'lifestyle-comorbidities-score', label: 'Lifestyle Comorbidities Score' },
        { type: 'lifestyle-score', label: 'Lifestyle Score' },
        { type: 'ear-file', label: 'EAR file' },
        { type: 'final-nutrition', label: 'Final Nutrition' },
        { type: 'recipes', label: 'Recipes' },
        { type: 'summary', label: 'Summary' },
        { type: 'lifestyle-reco', label: 'Lifestyle Recommendation' },
        { type: 'exercise-reco', label: 'Exercise Recommendation' },
        { type: 'relative-abundance', label: 'Relative Abundance' },
      ],
    }
  },
  computed: {
    isDataReviewReady() {
      return this.barcodeData && this.barcodeData.dataReview
    },
  },
  beforeMount() {
    this.showLoading()
    this.id = this.$route.params.id
    this.$http
      .get(`/barcodes/${this.id}`)
      .then(({ data }) => {
        this.barcodeData = data
      })
      .catch(this.handleError).finally(this.closeLoading)
  },
  methods: {
    // Fetch JSON data from an API endpoint
    async fetchData(endpoint) {
      try {
        const response = await axiosReportsIns.get(endpoint, {
          params: { barcode: this.barcodeData.duBarcode || this.barcodeData.stBarcode || this.barcodeData.saBarcode }, // Replace with your actual API endpoint and parameters
          responseType: 'blob', // Set response type to blob
        })
        return response.data || 'Not found'
      } catch (error) {
        this.$bvToast.toast('Error fetching JSON data. Please try again.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
        return null
      }
    },

    // Review button clicked: fetch and display JSON data
    async reviewJson(type) {
      let endpoint = ''
      switch (type) {
        case 'base-data':
          endpoint = '/v1/admin/base-data'
          break
        case 'biological-age-score':
          endpoint = '/v1/admin/biological-age-score'
          break
        case 'comorbidities':
          endpoint = '/v1/admin/comorbidities'
          break
        case 'diet-chart':
          endpoint = '/v1/admin/diet-chart'
          break
        case 'mb-wellness-risk':
          endpoint = '/v1/admin/mb-wellness-risk'
          break
        case 'mb-condition-risk':
          endpoint = '/v1/admin/mb-condition-risk'
          break
        case 'mb-condition':
          endpoint = '/v1/admin/mb-condition'
          break
        case 'mb-wellness':
          endpoint = '/v1/admin/mb-wellness'
          break
        case 'host-rank':
          endpoint = '/v1/admin/host-rank'
          break
        case 'host-genotype':
          endpoint = '/v1/admin/host-genotype'
          break
        case 'inference':
          endpoint = '/v1/admin/inference'
          break
        case 'health-score':
          endpoint = '/v1/admin/health-score'
          break
        case 'lifestyle-comorbidities-score':
          endpoint = '/v1/admin/lifestyle-comorbidities-score'
          break
        case 'lifestyle-score':
          endpoint = '/v1/admin/lifestyle-score'
          break
        case 'ear-file':
          endpoint = '/v1/admin/ear-file'
          break
        case 'final-nutrition':
          endpoint = '/v1/admin/final-nutrition'
          break
        case 'recipes':
          endpoint = '/v1/admin/recipes'
          break
        case 'summary':
          endpoint = '/v1/admin/summary'
          break
        case 'lifestyle-reco':
          endpoint = '/v1/admin/lifestyle-reco'
          break
        case 'exercise-reco':
          endpoint = '/v1/admin/exercise-reco'
          break
        case 'relative-abundance':
          endpoint = '/v1/admin/mb-relative-abundance'
          break
        default:
          endpoint = 'v1/admin/base-data'
          break
      }

      const blob = await this.fetchData(endpoint) // Wait for the JSON data to be fetched first

      // Ensure data is fetched
      if (blob) {
        const url = URL.createObjectURL(blob)
        window.open(url, '_blank')
      } else {
        this.$bvToast.toast('No data fetched yet. Please try again.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
    },
    // Publish data with comments
    async publishData() {
      try {
        await axiosReportsIns.post('v1/admin/data-publish', {
          barcode: this.barcodeData.duBarcode || this.barcodeData.stBarcode || this.barcodeData.saBarcode,
          remarks: this.comments,
        })

        this.$bvToast.toast('Data published successfully.', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      } catch (error) {
        this.$bvToast.toast('Error publishing data. Please try again.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
    },

    async changeDataStatus(status) {
      try {
        await axiosReportsIns.post('v1/admin/data-review', {
          barcode: this.barcodeData.duBarcode || this.barcodeData.stBarcode || this.barcodeData.saBarcode,
          status,
          remarks: this.comments,
        })

        this.$bvToast.toast(`Data ${status} successfully.`, {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      } catch (error) {
        this.$bvToast.toast('Error changing data status. Please try again.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
    },
  },
}
</script>

<style>
.modal-body-scroll {
  max-height: 90vh;
  overflow-y: auto;
}
.scrollable-dropdown .dropdown-menu {
  max-height: 200px; /* Adjust the height as needed */
  overflow-y: auto;
}
</style>
