<template>
  <div>
    <DataReview
      v-if="barcodeData && barcodeData.dataReview.status !== 'Rejected'"
      :current-step="currentStep"
    />
    <PdfReview
      v-if="barcodeData && barcodeData.pdfReview.status !== 'Rejected'"
      :current-step="currentStep"
    />
  </div>
</template>

<script>
import DataReview from './DataReview.vue'
import PdfReview from './PdfReview.vue'

/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
// import reportDataSchema from '@/schemas/reportData.json'
// import Ajv from 'ajv'

export default {
  components: {
    DataReview,
    PdfReview,
  },
  props: {
    currentStep: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: 0,
      id: null,
      barcodeData: null,
    }
  },
  beforeMount() {
    this.showLoading()
    this.id = this.$route.params.id
    this.$http
      .get(`/barcodes/${this.id}`)
      .then(({ data }) => {
        this.barcodeData = data
      })
      .catch(() => {
        this.barcodeData = {} // Initialize barcodeData to an empty object if the API call fails
        this.handleError()
      }).finally(this.closeLoading)
  },

}

</script>
